import "@aws-amplify/ui-react/styles.css";
import { useState} from 'react';
import { Authenticator } from "@aws-amplify/ui-react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import ExistingLicenses from './components/existing-licenses-component';
import LicenseDetails from './components/license-details-component';
import HomePage from './components/home-page-component';
import TopNavigation from './components/top-navigation-component';

import LicensesContext from './contexts/licenses-context';

export default function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#005060'
      }
    }
  });

  const [licenses, setLicenses] = useState({
    loaded: false,
    licenses: []
  });

  const value = { licenses, setLicenses };

  return (
    <Authenticator hideSignUp={true}>
      {({ signOut }) => (
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <TopNavigation signOut={signOut}/>
            <LicensesContext.Provider value={value}>
              <Routes>
                <Route path="/license/:id" element={ <LicenseDetails /> } />
                <Route path="/licenses" element={ <ExistingLicenses /> } />
                <Route path="/" element={ <HomePage /> } />
              </Routes>
            </LicensesContext.Provider>
          </BrowserRouter>
        </ThemeProvider>
      )}
    </Authenticator>
  );
}