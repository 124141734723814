import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const LicenseModifications = ({actions, qty}) => {
  return (
    <>
    {actions.length === 0 &&
      <Typography variant="body1">No available extensions or modifications.</Typography>}
    {actions.length > 0 && <TableContainer component={Paper}>
      <Table aria-label="modifications table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>SKU</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Reseller Price, USD</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {actions.map((mod, index) => (
            <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{mod.sku}</TableCell>
              <TableCell>{mod.descr}</TableCell>
              <TableCell>{qty > 1 && `${qty} × `}<s>{mod.price.full}</s>&nbsp;{mod.price.discounted}</TableCell>
              <TableCell>
                <Button target="_blank" variant="contained" color="success" size="small" href={mod.url} sx={{ mr: '0.5em' }}>
                  Place Order
                </Button>
                <Button target="_blank" variant="outlined" color="success" size="small" href={`${mod.url}&display-quote=TRUE`}>
                  Get Quote
                </Button>														
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
    </>
  );
};

export default LicenseModifications;