import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from '@mui/material/CircularProgress';
import AvailableLicenses from './available-licenses-component';

const HomePage = () => {
  //const reseller = props.reseller;
  //const news = reseller.news ?? [];

  // const [licenses, setLicenses] = useState({
  //   loaded: false,
  //   licenses: []
  // });

  // const value = { licenses, setLicenses };

  const [error, setError] = useState('');  
  const [loading, setLoading] = useState(true); 
  const [reseller, setReseller] = useState({
    name: '',
    discount: 0,
    news: [],
    products: []
  });

	useEffect(() => {
		API
			.post('apiResellers', '/reseller-info', { body: {} })
      .catch((e) => {
				setLoading(false);
        setError(e.message);
      })
			.then(response => {
				//console.log(response);
				setReseller(response);
				setLoading(false);
			});
  	}, []);

  return (
    <>
      {loading && <Box sx={{ my: 2, display: 'flex', justifyContent: 'center'}}><CircularProgress /></Box>}
      {!loading && error !== '' && <Alert severity="error">{error}</Alert>}
      {!loading && error === '' &&
        <Box sx={{ m: '1em' }}>
          <Typography variant="h3" component="h1">Welcome, {reseller.name}</Typography>
          <Alert severity="success" sx={{ my: 1 }}>
            <AlertTitle>Reseller discount {reseller.discount}%</AlertTitle>
          </Alert>
          {reseller.news.map((article, index) => (
            <Alert severity={article.severity} sx={{ my: 1 }} key={index}>
              {article.title !== '' && 
                <AlertTitle>{article.title}</AlertTitle>}
              {article.text}
            </Alert>
          ))}
          <Typography variant="h4" component="h2" sx={{ my: '0.5em' }}>Licenses available for purchase</Typography>
          {reseller.products !== undefined && reseller.products.map((product, index) => (
            <Box key={index}>
              <Typography variant="h5" component="h3" sx={{ my: '0.5em' }}>{product.name}</Typography>
              <AvailableLicenses licenses={product.licenses} />
            </Box>
          ))}
        </Box>
      }
    </>
  );
}

export default HomePage;