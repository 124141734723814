import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const LicenseHistory = (props) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="history table" size="small">
        <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Reference</TableCell>
          <TableCell>Record</TableCell>
        </TableRow>
        </TableHead>		
        <TableBody>
        {props.records.map((record, index) => (
          <TableRow
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
          <TableCell>{record.tm}</TableCell>
          <TableCell>{record.ref}</TableCell>
          <TableCell>{record.desc}</TableCell>
          </TableRow>
        ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LicenseHistory;