import { API } from 'aws-amplify';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CircularProgress from '@mui/material/CircularProgress';

import LicenseHistory from './license-history-component';
import LicenseModifications from './license-modifications-component';

const LicenseDetails = () => {
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(true);

  const { id } = useParams();
  useEffect(() => {
    API
      .post('apiResellers', '/license-details', { body: { id } })
      .then(response => {
        setDetails(response);
        setLoading(false);
      });
  }, []);

  const [tab, setTab] = useState("1");

  return (
    <>
      {loading && <Box sx={{  my: 2, display: 'flex', justifyContent: 'center'}}><CircularProgress /></Box>}
      {!loading && Object.keys(details).length !== 0 && 
        <>
          <Box sx={{ m: '0.5em', display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h4" component="h1" sx={{ display: 'inline' }}>License: {details.id}</Typography>
            {/* <Button variant="outlined" onClick={() => navigate(-1)}>Back to List</Button> */}
          </Box>				
          <Box sx={{ m: '0.5em' }}>
            <Typography variant="h5" component="h2">Product: <strong>{details.product}</strong></Typography>
            <Typography variant="h5" component="h2">Type: <strong>{details.qty} × {details.type}</strong></Typography>
            <Typography variant="h5" component="h2">Support tag: <strong>{details.tag}</strong></Typography>
            <Typography variant="h5" component="h2">Maintenance until: <strong>{details.maintenance}</strong></Typography>
            <Typography variant="h5" component="h2">Enduser: <strong>{details.enduser}</strong></Typography>
          </Box>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={(event, newValue) => { setTab(newValue); }} aria-label="tabs">
                  <Tab label="License Keys" value="1" />
                  <Tab label="License History" value="2" />
                  <Tab label="Extensions and Modifications" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                {details.keys.length === 0 && <>
                  <Typography variant="h6" component="h3">No license keys</Typography>
                  <Typography variant="body1">Please check <strong>License History</strong> for older keys.</Typography>
                </>}
                {details.keys.map((key, index) => (
                  <Typography variant="h6" component="h3" key={index}>{key}</Typography>
                ))}
              </TabPanel>							
              <TabPanel value="2">
                <LicenseHistory records={details.records} />
              </TabPanel>
              <TabPanel value="3">                
                <LicenseModifications actions={details.actions} qty={details.qty}/>
              </TabPanel>
            </TabContext>
          </Box>
        </>			
      }
    </>
  );
}

export default LicenseDetails;