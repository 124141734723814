import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

const AvailableLicenses = (props) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="modifications table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>SKU</TableCell>
            <TableCell>License Type</TableCell>
            <TableCell>Reseller Price, USD</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.licenses.map((license, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{license.sku}</TableCell>
              <TableCell>{license.descr}</TableCell>
              <TableCell><s>{license.price.full}</s>&nbsp;{license.price.discounted}</TableCell>
              <TableCell>
                <Button target="_blank" variant="contained" color="success" size="small" href={license.url} sx={{ mr: '0.5em' }}>
                  Place Order
                </Button>
                <Button target="_blank" variant="outlined" color="success" size="small" href={`${license.url}&display-quote=TRUE`}>
                  Get Quote
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
  
  export default AvailableLicenses;