import { useState, useEffect, useContext, useCallback } from 'react';
import { API } from 'aws-amplify';
import { DataGrid } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import { Link as RouterLink } from "react-router-dom";
import LicensesContext from '../contexts/licenses-context'
import './existing-licenses-component.css'

function getProduct(params) {
  if (params.row.sku.startsWith('SW-'))
    return 'Syslog Watcher';
  return 'Unknown';
}

function getType(params) {
  const type = params.row.sku.slice(params.row.sku.indexOf('-') + 1);
  switch(type) {
    case 'PRO':
      return `${params.row.qty} × Professional`;
    case 'ENT':
      return `${params.row.qty} × Enterprise`;
    case 'ULT':
      return `${params.row.qty} × Ultimate`;
    default:
      return 'Unknown';
  }
}

function onEnduserEdit(params) {
  //console.log(params);
  API
		.post('apiResellers', '/set-license-ref', {
      body: { id: params.id, ref: params.value }
    });
}

function getLicenseLink(params) {
  const link = '/license/' + params.row.guid;
  return <Link component={RouterLink} to={link}>{params.row.guid}</Link>;
}

const columns = [
  { field: 'guid', headerName: 'License ID', width:330, renderCell: getLicenseLink },
  { field: 'product', headerName: 'Product', width:130, valueGetter: getProduct },	
  { field: 'type', headerName: 'Type', width:110, valueGetter: getType },
  { field: 'maintenance', headerName: 'Maintenance', width:110 },
  { field: 'io', headerName: 'Initial Order', width:200 },
  { field: 'lo', headerName: 'Latest Order', width:200 },	
  { field: 'enduser', headerName: 'Enduser / Reference (double-click to edit)', flex:1, editable: true }
];

const getGuid = (item) => {
  //console.log(item);
  return item.guid;
}

const ExistingLicenses = () => {
  const context = useContext(LicensesContext);
  const [loading, setLoading] = useState(false);

  // const processRowUpdate = useCallback(
  //   async (newRow) => {
  //     console.log(newRow);
  //     // Make the HTTP request to save in the backend
  //     //const response = await mutateRow(newRow);
  //     //setSnackbar({ children: 'User successfully saved', severity: 'success' });
  //     //return response;
  //   },
  //   //[mutateRow],
  // );

  const loadLicenses = () => {
    setLoading(true);
    API
      .post('apiResellers', '/purchased-licenses', { body: { } })
      .then(response => {
        //console.log(response);
        context.setLicenses({ loaded: true, licenses: response });
        setLoading(false);
      });
  };

  useEffect(() => {
    // if (context.licenses.loaded) 
    //   return;
    loadLicenses();
  }, []);

  const processRowUpdate = useCallback((newRow) => {
    //console.log(newRow);
    return newRow;
    // async (newRow) => {
    //   console.log(newRow);
    //   // Make the HTTP request to save in the backend
    //   //const response = await mutateRow(newRow);
    //   //setSnackbar({ children: 'User successfully saved', severity: 'success' });
    //   return newRow;
    // }
  }, []);

  const handleProcessRowUpdateError = useCallback((error) => {
    console.log(error);
  }, []);

  return (
    <>
      <Box sx={{ m: '0.5em', display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h4" component="h1" sx={{ display: 'inline' }}>Purchased Licenses</Typography>
        <Button variant="outlined" disabled={loading} onClick={loadLicenses}>Refresh</Button>
      </Box>
      {loading && <Box sx={{ display: 'flex', justifyContent: 'center'}}><CircularProgress /></Box>}
      {!loading && 
        <DataGrid 
          rows={context.licenses.licenses} 
          columns={columns} 
          getRowId={getGuid} 
          autoHeight 
          rowHeight={36}
          disableColumnSelector
          initialState={{
            sorting: {
              sortModel: [{ field: 'maintenance', sort: 'desc' }],
            },
          }} 
          onCellEditCommit={onEnduserEdit}
          //processRowUpdate={processRowUpdate}
          //onProcessRowUpdateError={handleProcessRowUpdateError}
          //experimentalFeatures={{ newEditingApi: true }}          
          getRowClassName={(params) => params.row.maintenance < '2022-11-02' ? 'expired' : ''}
          components={{
            NoRowsOverlay: () => (
              <Box sx={{  my: 2, display: 'flex', justifyContent: 'center'}}>There are no purchased licenses with a Maintenance period that ended less than a year ago.</Box>
            )
          }}        
        />
      }
    </>
  );
}

export default ExistingLicenses;