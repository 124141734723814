import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';

import { Link as ReactRouterLink } from 'react-router-dom';

const pages = [ 	
    { name: 'Home', url: '/' }, 
    { name: 'Purchased Licenses', url: '/licenses' }
];

const TopNavigation = ({ signOut }) => {
  return (
    <AppBar position="static">
      <Container maxWidth="false">
        <Toolbar disableGutters>
          <Typography
              variant="h6"
              noWrap
              component={ReactRouterLink}
              to="/"
              sx={{
                  mr: 2,
                  display: 'flex',
                  fontWeight: 700,
                  color: 'inherit',
                  textDecoration: 'none',
              }}
          >
            EZ5 Reseller Portal
          </Typography>
          <Box sx={{ flexGrow: 1, display: 'flex' }}>
            {pages.map((page) => (
              <Button
                component={ReactRouterLink}
                key={page.url}
                to={page.url}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.name}
              </Button>
            ))}
          </Box>
          <Button
              onClick={signOut}
              sx={{ my: 2, color: 'white', display: 'block' }}
          >
            Sign Out
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
    
export default TopNavigation;
